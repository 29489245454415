// 我的巡检表头
export const myInspectionColumns = () => {
  return [
    {
      title: '序号',
      width: 40,
      scopedSlots: { customRender: '_index' },
      align: 'center'
    },
    {
      title: '巡检时间',
      align: 'center',
      width: 330,
      customRender: (text, record) => {
        //es6写法
        return `${record.startTime  ? record.startTime : ''} ~ ${record.endTime ? record.endTime : ''}`
      }
    },
    {
      title: '巡检时长',
      dataIndex: 'durationStr',
      align: 'center',
      width: 77,
      scopedSlots: { customRender: 'durationStr' }
    },
    {
      title: '巡检公里',
      dataIndex: 'mileage',
      width: 77,
      align: 'center'
    },
    {
      title: '巡检结果',
      dataIndex: 'result',
      align: 'center',
      width: 77,
      scopedSlots: { customRender: 'result' }
    },
    {
      title: '巡检状态',
      dataIndex: 'finish',
      align: 'center',
      width: 77,
      scopedSlots: { customRender: 'finish' }
    },
    {
      title: '巡检设备数量',
      dataIndex: 'totalAmount',
      width: 108,
      align: 'center',
      scopedSlots: { customRender: 'number' }
    },
    {
      title: '正常设备数量',
      dataIndex: 'normalAmount',
      width: 108,
      align: 'center',
      scopedSlots: { customRender: 'number' }
    },
    {
      title: '异常设备数量',
      dataIndex: 'abnormalAmount',
      width: 108,
      align: 'center',
      scopedSlots: { customRender: 'abnormalAmount' }
    }
  ]
}

// 巡检结果
export const inspectionResult = function () {
  return [
    { value: 'ABNORMAL', label: '正常' },
    { value: 'NOT_CHECK', label: '异常' }
  ]
}