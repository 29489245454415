<template>
  <page-layout class="fs-page">
    <div slot="headerContent">
      <h1 class="title">我的巡检</h1>
    </div>
    <a-card :bordered="false" style="margin-top: 20px;">
      <div class="fs-search-box" style="margin-bottom: 20px;">
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-form-item label="巡检结果">
              <a-radio-group @change="selectResult" v-model="searchParams.recordResult">
                <a-radio-button value="">全部</a-radio-button>
                <a-radio-button value="NORMAL">正常</a-radio-button>
                <a-radio-button value="ABNORMAL">异常</a-radio-button>
              </a-radio-group>
            </a-form-item>
            <a-form-item>
              <a-radio-group style="margin-right: 5px;" @change="handleChangeMetricsHistoryDate" v-model="dateSelect">
                <a-radio-button value="day">今日</a-radio-button>
                <a-radio-button value="week">本周</a-radio-button>
                <a-radio-button value="month">本月</a-radio-button>
                <a-radio-button value="year">全年</a-radio-button>
              </a-radio-group>
              <a-range-picker
                :value="metricsHistoryTime"
                :showTime="{ format: 'HH:mm' }"
                :allowClear="true"
                :placeholder="['开始时间', '结束时间']"
                format="YYYY-MM-DD HH:mm"
                @ok="handleModalPickerOk"
                @change="(value) => (metricsHistoryTime = value)"
              ></a-range-picker>
            </a-form-item>
            <a-form-item class="f-r m-r-none">
              <a-button type="primary" @click="search" icon="search">查询</a-button>
              <a-button class="m-l-6" @click="resetForm" icon="reload">重置</a-button>
            </a-form-item>
          </a-form>
        </div>
        <a-row :gutter="12">
          <a-spin :spinning="spinning">
            <a-col :sm="24" :md="12" :lg="8" :xl="4">
              <a-card>
                <a-statistic
                  title="巡检时长"
                  :value="inspectionStatistics.allDuration"
                  :valueStyle="valueStyle"
                  style="text-align: center;"
                >
                </a-statistic>
              </a-card>
            </a-col>
            <a-col :sm="24" :md="12" :lg="8" :xl="4">
              <a-card>
                <a-statistic
                  title="巡检公里"
                  :value="inspectionStatistics.allMileage"
                  :valueStyle="valueStyle"
                  style="text-align: center;"
                >
                </a-statistic>
              </a-card>
            </a-col>
            <a-col :sm="24" :md="12" :lg="8" :xl="4">
              <a-card>
                <a-statistic
                  title="巡检设备次数"
                  :value="inspectionStatistics.recordNodeTimes"
                  :valueStyle="valueStyle"
                  style="text-align: center;"
                >
                </a-statistic>
              </a-card>
            </a-col>
            <a-col :sm="24" :md="12" :lg="8" :xl="4">
              <a-card>
                <a-statistic
                  title="巡检次数"
                  :value="inspectionStatistics.totalTimes"
                  :valueStyle="valueStyle"
                  style="text-align: center;"
                >
                </a-statistic>
              </a-card>
            </a-col>
            <a-col :sm="24" :md="12" :lg="8" :xl="4">
              <a-card>
                <a-statistic
                  title="巡检正常次数"
                  :value="inspectionStatistics.normalTimes"
                  :valueStyle="valueStyle"
                  style="text-align: center; color: #1890ff;"
                >
                </a-statistic>
              </a-card>
            </a-col>
            <a-col :sm="24" :md="12" :lg="8" :xl="4">
              <a-card>
                <a-statistic
                  title="巡检异常次数"
                  :value="inspectionStatistics.abnormalTimes"
                  :valueStyle="valueStyle"
                  style="text-align: center; color: red;"
                >
                </a-statistic>
              </a-card>
            </a-col>
          </a-spin>
        </a-row>
      </div>
      <a-table
        size="middle"
        :columns="columns"
        :dataSource="tableData"
        :loading="tableLoading"
        :pagination="pagination"
        @change="tableChange"
        :customRow="click"
        :rowKey="(record) => record.id"
        bordered
      >
        <template slot="_index" slot-scope="text, record, index">
          {{ searchParams.number * searchParams.size + index + 1 }}
        </template>
        <template slot="time" slot-scope="text, record">
          {{ record.time }}
        </template>
        <template slot="result" slot-scope="text, record">
          <a-tag color="blue" v-if="record.result === 'NORMAL'">正常</a-tag>
          <a-tag color="red" v-if="record.result === 'ABNORMAL'">异常</a-tag>
        </template>
        <template slot="finish" slot-scope="text, record">
          <span v-if="record.finish === 'FINISHED'">完成</span>
          <span v-if="record.finish === 'UNFINISHED'">未完成</span>
        </template>
      </a-table>
    </a-card>
    <a-modal title="巡检详情" :visible="infoModal" :footer="null" :width="1400"
             centered @cancel="closeModal">
      <xunjian-passed ref="passedRef" :mapHeight="350" :dbDevicesData="dbDevicesData"></xunjian-passed>
    </a-modal>
  </page-layout>
</template>

<script>
import entityCRUDWithCopy from '../../common/mixins/entityCRUDWithCopy'
import mapDevices from '../../map/composite/mixins/map-devices'

import PageLayout from '@/components/page/PageLayout'
import SERVICE_URLS from '@/api/service.url'
import { myInspectionColumns } from './common/common'
import moment from 'moment'
import XunjianPassed from '../../map/composite/XunjianPassed'

export default {
  name: 'myInspection',
  mixins: [entityCRUDWithCopy, mapDevices],
  components: { PageLayout, XunjianPassed },
  data() {
    return {
      valueStyle: {
        color: '',
        textAlign: 'center',
      },
      entityBaseUrl: SERVICE_URLS.xj.xjRecord,
      searchParams: {
        number: '0',
        size: '10',
        direction: '',
        order: '',
        recordResult: '',
        startTime: '',
        endTime: '',
        userId: this.$store.getters.currentUser.id,
      },
      initColumns: myInspectionColumns(),
      infoModal: false,
      metricsHistoryTime: [],
      inspectionStatistics: {},
      dateSelect: '',
      spinning: true,
      detailsModal: false
    }
  },
  created() {
    this.getCountTimes()
  },
  methods: {
    handleChangeMetricsHistoryDate(e) {
      this.metricsHistoryTime = [moment().startOf(e.target.value), moment().endOf(e.target.value)]
      this.searchParams.startTime = this.metricsHistoryTime[0].format('YYYY-MM-DD HH:mm:ss')
      this.searchParams.endTime = this.metricsHistoryTime[1].format('YYYY-MM-DD HH:mm:ss')
      this.search()
      this.getCountTimes()
    },
    handleModalPickerOk(value) {
      this.searchParams.startTime = value[0].format('YYYY-MM-DD HH:mm:ss')
      this.searchParams.endTime = value[1].format('YYYY-MM-DD HH:mm:ss')
      this.search()
      this.getCountTimes()
    },
    click(record, index) {
      return {
        on: {
          click: () => {
            this.infoModal = true
            this.$nextTick(() => {
              this.$refs.passedRef.loadData(record.id)
            })
          },
        },
      }
    },
    closeModal() {
      this.infoModal = false
    },
    selectResult() {
      this.search()
      this.getCountTimes()
    },
    getCountTimes() {
      this.spinning = true
      this.$http(this, {
        url: SERVICE_URLS.xj.xjRecord.count_times,
        data: this.searchParams,
        noTips: true,
        success: (data) => {
          this.inspectionStatistics = data.body
          this.spinning = false
        },
      })
    },
    resetForm() {
      this.searchParams = {
        number: '0',
        size: '10',
        direction: '',
        order: '',
        recordResult: '',
        startTime: '',
        endTime: '',
        userId: this.$store.getters.currentUser.id,
      }
      this.dateSelect = ''
      this.metricsHistoryTime = []
      this.search()
      this.getCountTimes()
    },
    print() {
      this.detailsModal = true
    },
    closeDeatilsModal() {
      this.detailsModal = false
    }
  },
}
</script>
